import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/AppProvider";

import './App.css';

function App() {
	return (
		<AppStateProvider>
			<HashRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<Chat />} />
						<Route path="*" element={<NoPage />} />
					</Route>
				</Routes>
			</HashRouter>
		</AppStateProvider>
	);
}

export default App;